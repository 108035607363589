import React from "react";
import PropTypes from "prop-types";

import Layout from "./components/layout";
import SEO from "./components/seo";
import BuildingList from "./components/building-list";
import BuildingSearch from "./components/building-search";
import Buildings from "../../common/Buildings";
import Paginator from "../../common/Paginator";
import { templateVariableValue } from "../../helpers/templateVariables";
import { translateStatic } from "../../helpers/translationHelper";
import * as theme4Styles from "./scss/theme4.module.scss";

// import downArrow from "./images/down-arrow-color.png";

class Theme4Buildings extends Buildings {
  constructor(props) {
    super(props);
  }

  renderBuildings() {
    const { 
      result, 
      location 
    } = this.props;

    const {
      currentLocaleKey: locale, 
      defaultLocaleKey: defaultLocale
    } = result;

    return (
      <div className={`${theme4Styles.mainContent}`}>
        <div className={theme4Styles.wrapper}>
          <div className={`${theme4Styles.propHeader}`}>
            <div className={`${theme4Styles.propHeaderLeft}`}>
              <h2 className={`${theme4Styles.pageTitle}`}>
                {translateStatic("buildings", locale, defaultLocale)}
              </h2>
            </div>
            <div className={`${theme4Styles.propHeaderRight}`}>
              <select
                className={`${theme4Styles.field}`}
                value={this.state.sort}
                onChange={this.handleSortChange}
              >
                <option value="">Sort</option>
                {Object.keys(this.state.sortOptions).map((key) => {
                  return (
                    <option value={key} key={key}>
                      {this.state.sortOptions[key]}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className={`${theme4Styles.pageTitleBottom}`}>
            <span className={`${theme4Styles.resultCount}`}>
              {this.state.searchedBuildings.length}{" "}
              {translateStatic("results", locale, defaultLocale)}
            </span>
          </div>
          <BuildingList
            buildings={this.state.buildings}
            locale={locale}
            defaultLocale={defaultLocale}
          />
          <Paginator
            themeStyles={theme4Styles}
            paginator={this.state.paginatorInfo}
            prefix={this.state.navigationPrefix}
            search={location.search}
          />
        </div>
        {(() => {
          if (this.state.paginatorInfo.total == 0) {
            return (
              <div className={`${theme4Styles.noDataMsg}`}>
                <span>
                  {translateStatic("no_building_found", locale, defaultLocale)}
                </span>
              </div>
            );
          }
        })()}
      </div>
    );
  }

  render() {
    const { 
      result, 
      pageContext, 
      location
    } = this.props;

    const {
      allBuildings, 
      propertyTypes
    } = pageContext;

    const {
      remarso: {
        domainByURL: {
          website: {
            templateConfiguration,
            template: { templateVariables },
          },
        },
      },
      currentLocaleKey: locale, 
      defaultLocaleKey: defaultLocale
    } = result;

    const metaTitle = templateVariableValue(
      templateConfiguration,
      templateVariables,
      "meta-title-buildings",
      locale,
      defaultLocale
    );
    
    return (
      <Layout>
        <SEO metaTitle={metaTitle} />

        <div className={`${theme4Styles.listingHeaderC}`}>
          <div className={theme4Styles.wrapper}>
            <div className={`${theme4Styles.listingHeader}`}>
              <BuildingSearch
                buildings={allBuildings}
                setBuildings={this.setBuildings}
                propertyTypes={propertyTypes}
                locale={locale}
                defaultLocale={defaultLocale}
                navigationPrefix={this.state.navigationPrefix}
                location={location}
              />
            </div>
          </div>
        </div>

        {this.renderBuildings()}
      </Layout>
    );
  }
}

export default Theme4Buildings;

Theme4Buildings.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
  location: PropTypes.object,
};
