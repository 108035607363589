import React from "react";
import PropTypes from "prop-types";

import WithThemeLoading from "../hoc/withThemeLoading";
import Theme1Buildings from "../themes/theme1/theme1Buildings";
import Theme2Buildings from "../themes/theme2/theme2Buildings";
import Theme3Buildings from "../themes/theme3/theme3Buildings";
import Theme4Buildings from "../themes/theme4/theme4Buildings";
import Theme5Buildings from "../themes/theme5/theme5Buildings";

const Buildings = (props) => {
  /** Begin: Theme Switcher */
  const templateId =
    process.env.GATSBY_TEST_THEME === "0"
      ? props.pageContext.template.id
      : process.env.GATSBY_TEST_THEME;
  const themes = [
    Theme1Buildings,
    Theme2Buildings,
    Theme3Buildings,
    Theme4Buildings,
    Theme5Buildings,
  ];
  const ThemeLoading =
    parseInt(templateId) > 0
      ? WithThemeLoading(themes[parseInt(templateId) - 1])
      : WithThemeLoading(Theme1Buildings);
  /** End: Theme Switcher */

  return <ThemeLoading {...props} />;
};

Buildings.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Buildings;
